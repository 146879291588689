<template>
  <v-row class="ma-0">
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Email confirmation expired</div>
        <p class="text-center opasity--text mt-3">Sorry, but time for confirmation an email expired. Try resent a request.</p>
        <v-btn @click="again" width="300" height="48" class="mx-auto" color="secondary">Sent confirmation link again</v-btn>
        <router-link class="d-block opasity--text mt-5" to="/sign-up"><v-icon left>mdi-arrow-left</v-icon> Back to Sign Up</router-link>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: null,
    };
  },
  methods: {
    notifi() {
      if (this.error == 'confirmation__too_often') {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'email__already_confirmed') {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'account__deactivated') {
        this.$notify({
          message: 'Sorry, but your account has been deactivated',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async again() {
      sessionStorage.setItem('jwt_token', this.$route.query.t);
      await this.$store
        .dispatch('sendEmail')
        .then(() => {
          this.router.push('/re-sent');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
  },
};
</script>
